import clsx from 'clsx';
import PropTypes from 'prop-types';
import './style.scss';

const List = ({
  className,
  children,
}) => {
  return (
    <ul className={className}>
      {children}
    </ul>
  );
};

const ListItem = ({ children, onClick, className }) => {
  return (
    <li
      className={clsx('flex text-start', className)}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

List.defaultProps = {
  className: '',
};

List.propTypes = {
  className: PropTypes.string,
};

List.Item = ListItem;

export default List;
