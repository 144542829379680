import { useMountEffect } from '@hooks/useMountEffect';
import { usePageVisibility } from '@hooks/usePageVisibility';
import { useEffect, useRef, useState } from 'react';
import ReactTicker from 'react-ticker';

const defaultProps = {
  speed: 7,
};

const TickerContent = ({ getNextItem, renderItem, index }) => {
  return renderItem(getNextItem(), index);
};

const ItemsTicker = ({ items = [], tickerProps, renderItem }) => {
  const settings = { ...defaultProps, ...tickerProps };

  const pageVisible = usePageVisibility();

  const [visible, setVisible] = useState(pageVisible);

  const mounted = useMountEffect(() => {
    // At the first render, gaps between the elements are incorrect, so we immediately trigger the second render.
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 500);
  });

  useEffect(() => {
    if (mounted) {
      setTimeout(() => setVisible(pageVisible), 0);
    }
  }, [mounted, pageVisible]);

  const itemsRef = useRef(items);

  const getNextItem = () => {
    let newItems = [...itemsRef.current];
    if (newItems.length === 0) {
      newItems = [...items];
    }
    const next = newItems.shift();
    itemsRef.current = newItems;
    return next;
  };

  return visible ? (
    <ReactTicker {...settings}>
      {(index) => (
        <TickerContent
          index={index}
          getNextItem={getNextItem}
          renderItem={renderItem}
        />
      )}
    </ReactTicker>
  ) : null;
};

export default ItemsTicker;
