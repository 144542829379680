import { useCallback, useEffect, useState } from 'react';

const windowVisibilityEvents = ['pageshow', 'pagehide', 'focus', 'blur'];

const browserCompatApi = () => {
  let hiddenPropName;
  let changeEventName;

  if (typeof document !== 'undefined') {
    if ('hidden' in document) {
      hiddenPropName = 'hidden';
      changeEventName = 'visibilitychange';
    } else if ('mozHidden' in document) {
      // Firefox up to v17
      hiddenPropName = 'mozHidden';
      changeEventName = 'mozvisibilitychange';
    } else if ('webkitHidden' in document) {
      // Chrome up to v32, Android up to v4.4, Blackberry up to v10
      hiddenPropName = 'webkitHidden';
      changeEventName = 'webkitvisibilitychange';
    }
  }

  return {
    hiddenPropName,
    changeEventName,
  };
};

export const usePageVisibility = () => {
  const [visible, setVisible] = useState(true);

  const {
    hiddenPropName,
    changeEventName,
  } = browserCompatApi();

  const handleChange = useCallback((e) => {
    if (visible && (['blur', 'focusout', 'pagehide'].includes(e.type) || (document && document[hiddenPropName]))) {
      setVisible(false);
    } else if (!visible && (['focus', 'focusin', 'pageshow'].includes(e.type) || (document && !document[hiddenPropName]))) {
      setVisible(true);
    }
  }, [hiddenPropName, visible]);

  useEffect(() => {
    if (changeEventName && document) {
      document.addEventListener(changeEventName, handleChange);
      return () => document.removeEventListener(changeEventName, handleChange);
    }
  }, [changeEventName, handleChange]);

  useEffect(() => {
    if (window) {
      const removeCallbacks = [];
      windowVisibilityEvents.forEach((eventName) => {
        window.addEventListener(eventName, handleChange);
        removeCallbacks.push(() => window.removeEventListener(eventName, handleChange));
      });
      return () => {
        removeCallbacks.forEach((callback) => callback());
      };
    }
  }, [handleChange]);

  return visible;
};
