import PropTypes from 'prop-types';
import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTicker';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import './StatTicker.scss';

const {
  block,
  element,
} = bem('stat-ticker');

const ItemCard = ({ item }) => {
  return (
    <div className="py-3 px-4.5 drop-shadow-[6px_6px_40px_#ECF2FF] sm:p-3 lg-down:first:pl-0">
      <div className="w-[204px] lg-down:text-left">
        <p {...element('item-value')}>
          {item.value}
        </p>
        <Typography
          variant="body2"
          color="deepBlue4"
          className="mt-1.5"
        >
          {item.indicator}
        </Typography>
      </div>
    </div>
  );
};

const StatTicker = ({ tickerItems }) => {
  return (
    <div {...block()}>
      <div className="lg-down:hidden">
        <ItemsTicker
          items={tickerItems}
          renderItem={(item, i) => (
            <div key={i} {...element('item-container')}>
              <p {...element('item-value')}>
                {item.value}
              </p>
              <Typography
                {...element('item-indicator')}
                variant="body2"
                color="deepBlue4"
              >
                {item.indicator}
              </Typography>
            </div>
          )}
        />
      </div>
      <MediaQuery lessThan="lg">
        <div className="md:grid-layout sm:pl-3 md:mx-auto">
          <div
            {...element(
              'items-container',
              {},
              'mb-8 flex md:space-x-1.5 overflow-x-scroll pt-7 sm:pb-4 md:pb-3',
            )}
          >
            {
              tickerItems.map((x, i) => (
                <ItemCard item={x} key={i} />
              ))
            }
          </div>
        </div>
      </MediaQuery>
    </div>
  );
};

StatTicker.propTypes = {
  tickerItems: PropTypes.array.isRequired,
};

export default StatTicker;
