import StaticIcon from '@ui/StaticIcon';
import Typography from '@ui/Typography';
import clsx from 'clsx';

const CheckText = ({
  text,
  className,
}) => {
  return (
    <div className={clsx(
      className,
      'flex',
    )}
    >
      <div className="h-2 w-2 py-0.5">
        <StaticIcon folder="main-page" name="check" />
      </div>
      <Typography className="ml-[10px]">
        {text}
      </Typography>
    </div>
  );
};

export default CheckText;
