import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import StaticIcon from '@ui/StaticIcon';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import clsx from 'clsx';

import './PilotProject.scss';
import { Fragment } from 'react';
import { TryButton } from '@ui/TryButton';

const {
  block,
  element,
} = bem('pilot-project');

const tr = (key) => t(`main_page.pilot_project.${key}`);

const PilotProject = () => {
  return (
    <>
      <MediaQuery at="sm">
        <div>
          <div className="text-left sm:px-3">
            <Typography variant="header2" tag="h5" className="font-semibold">
              {tr('title')}
            </Typography>
            <Typography variant="subhead1" className="mt-3 text-deep-blue-3">
              {tr('subtitle')}
            </Typography>
          </div>
          <div className="grid-layout mx-auto py-7 sm:px-3">
            <TryButton 
              text={tr('request_consultation')}
              className="w-full"
              blank
            />
          </div>
          <div className="mt-5 mb-8 flex justify-center">
            <StaticIcon
              folder="main-page"
              name="help-each-stage"
            />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className={clsx(
          'md-up:grid-layout relative md-up:mx-auto lg:flex lg:flex-col lg:py-15',
          'md:my-8',
        )}
        >
          <div className="lg:col-offset-start-1 lg:col-10 md:text-center lg:text-center">
            <Typography variant="header2" tag="h5" className="font-semibold">
              {tr('title')}
            </Typography>
            <Typography variant="subhead1" className="text-deep-blue-3 md:mt-3 lg:mt-4">
              {tr('subtitle')}
            </Typography>
            <div className="relative">
              <TryButton 
                text={tr('request_consultation')}
                className="md:mt-4 lg:mt-5"
                blank
              />
            </div>
          </div>
          <div className="mt-5 flex justify-center">
            <StaticIcon
              folder="main-page"
              name="help-each-stage"
            />
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default PilotProject;
