import clsx from 'clsx';
import { useMediaQuery } from '@hooks/useMediaQuery';

import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import StaticIcon from '@ui/StaticIcon';
import Slider from '@ui/Slider';

const NUMBER_OF_CARDS = 3;

const Wrapper = ({
  link, children, className, key,
}) => {
  if (link) {
    return <a href={link} target="_blank" className={className} key={key}>{children}</a>;
  }

  return <div className={className} key={key}>{children}</div>;
};

const IndustryCard = ({ item }) => {
  const {
    title, body, color, icon, link,
  } = item;

  const onCardClick = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const isMobile = useMediaQuery('(max-width: 687px)');

  return (
    <Wrapper 
      link={link}
      className={clsx(
        'industries__card group flex items-center rounded-2xl bg-white md:rounded-xl md:shadow-[6px_6px_40px_#ECF2FF;]',
      )}
    >
      <div className={clsx(
        color,
        'md-up:border-r-solid py-7 px-4 sm:py-3 sm:px-2.5 md:px-3.5 md:py-4 md-up:border-r-[2px] md-up:border-r-tertiary300',
        'rounded-l-2xl border-[2px] border-solid border-y-tertiary300 border-l-tertiary300 sm:border-r-0 md:rounded-l-xl',
        !isMobile && color === 'teal' && 'group-hover:border-teal400 group-hover:bg-teal300',
        !isMobile && color === 'orange' && 'group-hover:border-orange400 group-hover:bg-orange300',
        !isMobile && color === 'cyan' && 'group-hover:border-cyan400 group-hover:bg-cyan300',
        !isMobile && color === 'purple' && 'group-hover:border-purple400 group-hover:bg-purple300',
        !isMobile && color === 'pink' && 'group-hover:border-pink400 group-hover:bg-pink300',
        !isMobile && color === 'yellow' && 'group-hover:border-yellow400 group-hover:bg-yellow300',
      )}
      >
        <StaticIcon 
          name={icon}
          className="h-6 w-6"
        />
      </div>
      <div className={clsx(
        'flex h-[164px] w-full flex-col justify-center sm:h-[100px] md:h-[116px] lg:justify-start',
        'rounded-r-2xl border-[2px] border-l-0 border-solid border-tertiary300 sm:pr-3 sm:text-start md:rounded-r-xl md-up:pl-3 md-up:pr-4 lg:pt-3',
      )}
      >
        <Typography className="font-semibold lg:mb-1">
          {title}
        </Typography>
        <Typography className="hidden text-xs !text-secondary200 lg:block">
          {body}
        </Typography>
      </div>
    </Wrapper>
  );
};

const getMobileCards = (arr) => {
  const numberOfSlides = arr.length % NUMBER_OF_CARDS === 0 ? arr.length / NUMBER_OF_CARDS : (arr.length / NUMBER_OF_CARDS) + 1;
  const newArr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberOfSlides; i++) {
    newArr.push(arr.slice(i * 3, (i + 1) * 3));
  }

  return newArr;
};

export default function Industries({ t, BlockCaption }) {
  const cards = t('industries.cards');
  const mobileCards = getMobileCards(cards);
  
  return (
    <div
      id="industries" 
      className={clsx(
        'mx-1 px-5 pb-12 sm:px-2 sm:pb-7 sm:pt-8 md:px-[28px] md:py-8 lg:mx-3 lg:mt-[-109px] lg:pt-[180px] lg-down:scroll-mt-18',
        'bg-[linear-gradient(180deg,rgba(243,247,255,0)0%,#F3F7FF_100%)] rounded-b-2xl sm:rounded-b-lg md:rounded-b-xl',
      )}
    >
      <BlockCaption textClassName="text-orange500 bg-[rgba(211,39,0,0.08)]">
        {t('industries.caption')}
      </BlockCaption>
      <Typography
        variant="header2"
        center
        className="mt-4 sm:mt-3"
      >
        {t('industries.title')}
      </Typography>

      <MediaQuery greaterThan="sm">
        <div
          className={clsx(
            'mx-auto max-w-[1312px] gap-3 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 lg:gap-4 xl:grid-cols-3',
            'mt-9 md:mt-7',
          )}
        >
          {cards.map((item) => (
            <IndustryCard item={item} key={item.icon} />
          ))}
        </div>
      </MediaQuery>
      <MediaQuery at="sm">
        <div className="mt-5">
          <Slider gap={2}>
            {mobileCards.map((cardGroup, index) => (
              <div className="flex flex-col space-y-3" key={index}>
                {cardGroup.map((card) => (
                  <IndustryCard item={card} key={card.icon} />
                ))}
              </div>
            ))}
          </Slider>
        </div>
      </MediaQuery>
    </div>
  );
}
