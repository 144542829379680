import { bem } from '@lib/bem';
import './CircleBlur.scss';

const { block } = bem('circle-blur');

const CircleBlur = ({ className }) => {
  return (
    <div {...block({}, className)} />
  );
};

export default CircleBlur;
