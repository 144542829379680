import { usePrevious } from '@hooks/usePrevious';
import { bem } from '@lib/bem';

import StaticIcon from '@ui/StaticIcon';
import Image from '@ui/Image';
import List from '@ui/List';
import MediaQuery from '@ui/MediaQuery';
import MobileApps from '@ui/MobileApps';
import ProgressLine from '@ui/ProgressLine';
import { displayProgress } from '@ui/ProgressLine/ProgressLine';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import './WorkWithIssues.scss';
import { useInView } from 'react-intersection-observer';

const {
  block,
  element,
} = bem('work-with-issues');

const Rating = ({ number }) => {
  return (
    <div className="row">
      {
        [...Array(number).keys()].map((i) => (
          <StaticIcon key={i} name="star" {...element('rating-star')} />
        ))
      }
    </div>
  );
};

const ItemIcon = ({
  name,
  selected,
  className,
}) => {
  return (
    <div {...element('icon', {}, '!relative h-4 w-4 ')}>
      <div className="!h-4 !w-4 rounded-lg !bg-blue-5 !shadow-[inset_-4px_-4px_8px_#FFFFFF]" />
      <StaticIcon
        name={selected ? `${name}-active` : name}
        folder="main-page"
        className="absolute top-0.5 left-0.5"
      />
    </div>
  );
};

export const JobCard = ({
  item,
  className,
}) => {
  return (
    <div className={clsx(
      'w-full md:w-[300px]',
      'px-1',
      'pt-4',
      'sm:pb-9 md:pb-[58px]',
      'bg-pastel-violet-3',
      'rounded-[12px]',
      'text-center',
      'flex-col',
      'flex',
      'items-center',
      className,
    )}
    >
      <div className="flex w-[256px] flex-col items-center justify-center">
        <ItemIcon name={item.icon} selected />
        <Typography className="mt-[18px] h-[48px] lg-down:font-medium flex items-center">
          {item.title}
        </Typography>
        <Typography variant="body2" className="mt-[10px] h-[40px]">
          {item.body}
        </Typography>
      </div>
      <div className="mt-4 w-[299px]">
        <Image alt={item.image_alt} src={item.image} className="h-[429px]" />
      </div>
    </div>
  );
};

const StageDescriptionRow = ({
  item,
  selected,
  last,
  progressLineRef,
  index,
}) => {
  return (
    <div className="flex w-full flex-col">
      <div {...element('list-item', {}, 'flex w-full items-center')}>
        <div className="flex flex-col">
          <div className="flex items-center">
            <ItemIcon name={item.icon} selected={selected} />
            <Typography
              weight="medium"
              color={selected ? 'deepBlue2' : 'deepBlue5'}
              {...element('list-item-title', {}, 'ml-[18px]')}
            >
              {item.title}
            </Typography>
          </div>
          {
            selected ? (
              <Typography variant="body2" color="deepBlue4" className="mt-0.5 ml-[50px]">
                {item.body}
              </Typography>
            ) : null
          }
        </div>
        {selected && (
          <div className="ml-auto">
            <StaticIcon name="arrow-right" />
          </div>
        )}
      </div>
      <ProgressLine
        ref={(el) => {
          // eslint-disable-next-line no-param-reassign
          progressLineRef.current[index] = el;
        }}
        className={clsx(
          '!h-[1px] !bg-blue-3',
          last ? 'mt-1.5' : 'my-1.5',
        )}
        progressIndicatorClassName="!h-[1px]"
      />
    </div>
  );
};

const GROUPS_CHANGING_INTERVAL = 6 * 1000;

const WorkWithIssues = ({
  t,
  BlockCaption,
}) => {
  const blockName = 'work_with_issues';
  const switchItems = t('switch_items', blockName);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const progressLineRef = useRef([]);
  const intervalRef = useRef(null);
  const selectedGroupDisplayDuration = useRef(0);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const previousSelectedItemIndex = usePrevious(selectedItemIndex);

  const displayProgressForSelectedGroup = useCallback((oldSelectedIndex, newSelectedIndex) => {
    const oldLine = progressLineRef.current[oldSelectedIndex];
    const newLine = progressLineRef.current[newSelectedIndex];

    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }

    const displayedDuration = selectedGroupDisplayDuration.current;

    if (!displayedDuration) {
      displayProgress(oldLine, 0);
    }

    const startDate = new Date();
    intervalRef.current = setInterval(() => {
      const diff = displayedDuration + (new Date() - startDate);
      selectedGroupDisplayDuration.current = diff;
      displayProgress(newLine, diff / GROUPS_CHANGING_INTERVAL);
      if (diff >= GROUPS_CHANGING_INTERVAL) {
        clearInterval(intervalRef.current);
        // setTimeout because progress line has transition duration.
        setTimeout(() => {
          if (newSelectedIndex + 1 === switchItems.length) {
            setSelectedItemIndex(0);
          } else {
            setSelectedItemIndex(newSelectedIndex + 1);
          }
        }, 300);
        selectedGroupDisplayDuration.current = 0;
      }
    }, 70);
  }, [switchItems]);

  const selectGroup = (index) => () => {
    selectedGroupDisplayDuration.current = 0;
    setSelectedItemIndex(index);
  };

  useEffect(() => {
    if (inView) {
      displayProgressForSelectedGroup(previousSelectedItemIndex, selectedItemIndex);
    } else if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
  }, [displayProgressForSelectedGroup, inView, previousSelectedItemIndex, selectedItemIndex]);

  return (
    <>
      <MediaQuery lessThan="lg">
        <div
          className={
            clsx(
              'flex-column md:grid-layout sm:mt-7 md:mx-auto md:mt-8',
              element('slider').className,
            )
          }
        >
          <BlockCaption color="blue2" bgcolor="blue4">
            {t('caption', blockName)}
          </BlockCaption>
          <Typography variant="header2" center className="mt-4 mb-7 sm:mx-3 sm:mt-3">
            {t('title', blockName)}
          </Typography>
          <MediaQuery at="sm">
            <div>
              <div className="mx-1">
                <Slider>
                  {
                    switchItems.map((item, i) => (
                      <JobCard item={item} key={i} className="mx-auto" />
                    ))
                  }
                </Slider>
              </div>
              <div {...element('mobile-app-info', {}, 'py-4 space-y-1.5')}>
                <Typography variant="body2" color="deepBlue4">
                  {t('mobile.description', blockName)}
                </Typography>
                <Rating number={5} />
                <Typography {...element('mobile-rating')} variant="body2" weight="medium">
                  {t('mobile.rating', blockName)}
                </Typography>
                <MobileApps className="!mt-2 flex-col space-y-1.5" multicolor={false} />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery at="md">
            <div>
              <div>
                <Slider
                  settings={{
                    slidesToShow: 2.2,
                    slidesToScroll: 2,
                  }}
                >
                  {
                    switchItems.map((item, i) => (
                      <JobCard item={item} key={i} />
                    ))
                  }
                </Slider>
              </div>
              <div {...element('mobile-app-info', {}, 'flex-column-md center')}>
                <MobileApps className="mt-7 flex space-x-1.5" multicolor={false} />
                <div className="row mt-3">
                  <Rating number={5} />
                  <Typography {...element('mobile-rating')} variant="body2" weight="medium">
                    {t('mobile.rating', blockName)}
                  </Typography>
                  <Typography className="ml-2" variant="body2" color="deepBlue4">
                    {t('mobile.description', blockName)}
                  </Typography>
                </div>
              </div>
            </div>
          </MediaQuery>
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="md">
        <div className="row mt-12" ref={ref}>
          <div {...block()}>
            <BlockCaption color="blue2" bgcolor="blue4">
              {t('caption', blockName)}
            </BlockCaption>
            <div className="grid-layout">
              <div className="lg:col-offset-start-1 lg:col-10 p-0  ">
                <Typography variant="header2" center className="mt-4">
                  {t('title', blockName)}
                </Typography>
                <div className="row">
                  <div className="lg:col-5">
                    <List className="mt-9">
                      {switchItems.map((item, i) => (
                        <List.Item onClick={selectGroup(i)} key={i}>
                          <StageDescriptionRow
                            item={item}
                            selected={i === selectedItemIndex}
                            last={i === switchItems.length - 1}
                            progressLineRef={progressLineRef}
                            index={i}
                          />
                        </List.Item>
                      ))}
                    </List>
                    <MobileApps className="mt-6 mb-3 flex space-x-1.5" multicolor={false} />
                    <div className="row">
                      <Rating number={5} />
                      <Typography variant="body2" weight="medium" {...element('mobile-rating')}>
                        {t('mobile.rating', blockName)}
                      </Typography>
                      <Typography className="ml-2 whitespace-nowrap" variant="body2" color="deepBlue4">
                        {t('mobile.description', blockName)}
                      </Typography>
                    </div>
                  </div>
                  {
                    switchItems[selectedItemIndex] ? (
                      <div {...element('switch-item-image')}>
                        <Image
                          alt={switchItems[selectedItemIndex].image_alt}
                          src={switchItems[selectedItemIndex].image}
                        />
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

WorkWithIssues.propTypes = {
  t: PropTypes.func.isRequired,
  BlockCaption: PropTypes.elementType.isRequired,
};

export default WorkWithIssues;
