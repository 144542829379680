import { RequestFeedback } from '@features/request-feedback';
import Image from '@ui/Image';

import Typography from '@ui/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import CheckText from '../CheckText';
import { CircleBlur } from '../CircleBlur';

const CTA = ({
  t,
  items,
}) => {
  return (
    <div className="grid-layout my-7 mx-auto sm:px-3">
      <div className="flex md-lg:text-center xl-down:flex-col">
        <div className="xl:col-6 xl:mt-[342px] xl:flex xl:flex-col">
          <Typography variant="header2">
            {t('cta.title')}
          </Typography>
          <div className="flex flex-col md-lg:flex-col-reverse md-lg:items-center md-lg:text-center">
            <div className="flex space-x-2 sm:hidden xl:my-5">
              {
                React.Children.toArray(items.map((item, i) => (
                  <CheckText text={item} />
                )))
              }
            </div>
            <div className="flex sm:mt-5 sm:flex-col sm:space-y-2 md-up:space-x-2 md-lg:my-5">
              <RequestFeedback.Demo.Button className="md-lg:w-[196px]">
                {t('request_demo')}
              </RequestFeedback.Demo.Button>
              <RequestFeedback.Call.Button className="md-lg:w-[196px]" outline>
                {t('request_call')}
              </RequestFeedback.Call.Button>
            </div>
          </div>
        </div>
        <div className="sm:mt-6 md-lg:mt-[88px] xl:mt-[120px] xl:ml-auto">
          <div className="relative inline-block">
            <CircleBlur className="sm:hidden md-lg:top-6 md-lg:right-[122px] xl:top-[108px] xl:right-[152px]" />
            <Image 
              alt={t('cta.image_alt')} 
              src={t('cta.image')}
              className={clsx(
                'sm:top-[9px] sm:left-[50px] sm:h-[388px] sm:w-[308px]',
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CTA.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default CTA;
