import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import MediaQuery from '@ui/MediaQuery';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './DiscussProblemBlock.scss';

const {
  block,
  element,
} = bem('discuss-problem-block');

const BlockContent = ({
  className,
  t,
}) => {
  return (
    <div {...element('card', {}, className)}>
      <div className="md:col-8 md-up:col-offset-start-1 lg:col-10 sm:mx-auto sm:px-3 sm:py-7 md:py-8 lg:py-9">
        <div className="row middle-lg flex-column-less-than-lg sm:text-left md:text-center">
          <div className="flex-column">
            <Typography variant="header5">
              {t('discuss_problem.header')}
            </Typography>
            <Typography variant="subhead1" {...element('discuss-problem-subhead', {}, 'mt-2-lg lg:mr-2 mt-3-less-than-lg')}>
              {t('discuss_problem.subheader')}
            </Typography>
          </div>
          <RequestFeedback.Problem.Button
            {...element(
              'discuss-problem-button',
              {},
              'ml-auto-lg mt-4-less-than-lg md:self-start md:mx-auto',
            )}
          >
            {t('discuss_problem.submit_button_text')}
          </RequestFeedback.Problem.Button>
        </div>
      </div>
    </div>
  );
};

const DiscussProblemBlock = ({ t }) => {
  return (
    <div {...block({}, '')}>
      <MediaQuery at="sm">
        <div className="mx-3">
          <BlockContent t={t} />
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className="grid-layout mx-auto">
          <BlockContent t={t} />
        </div>
      </MediaQuery>
    </div>
  );
};

DiscussProblemBlock.propTypes = {
  t: PropTypes.func.isRequired,
};

export default DiscussProblemBlock;
