import Image from '@ui/Image';
import ItemsTicker from '@ui/ItemsTickerNew';

const logos = ['swvending', 'swws', 'bni', 'duplex', 'sovereign'];

const logoComponents = logos.map((name) => {
  return (
    <Image
      src={`/images/client-logos/${name}.png`}
      className="mx-5 h-6 sm:mx-3 md:mx-2.5 lg:h-8"
      key={name}
      alt={`${name}_logo`}
    />
  );
});

const ClientLogos = ({ className }) => {
  return (
    <div className={className}>
      <ItemsTicker 
        items={logoComponents}
      />
    </div>
  );
};

export default ClientLogos;
