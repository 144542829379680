import { bem } from '@lib/bem';
import AccordionMenu from '@ui/AccordionMenu';
import MediaQuery from '@ui/MediaQuery';

import Typography from '@ui/Typography';
import PropTypes from 'prop-types';

import './ExtraFeatures.scss';

const {
  block,
  element,
} = bem('extra-features');

const FeatureCard = ({ item }) => {
  return (
    <div className="h-full">
      <MediaQuery lessThan="lg">
        <Typography {...element('item-description')}>
          {item.description}
        </Typography>
      </MediaQuery>
      <MediaQuery greaterThan="md">
        <div {...element('item-content', {}, 'h-full')}>
          <Typography {...element('item-title')} weight="medium">
            {item.title}
          </Typography>
          <Typography {...element('item-description', {}, 'mt-2')}>
            {item.description}
          </Typography>
        </div>
      </MediaQuery>
    </div>
  );
};

const ExtraFeatures = ({
  t,
  BlockCaption,
  items,
}) => {
  const blockName = 'extra_features';

  const accordionItems = items.map((x) => ({
    title: x.title,
    renderContent: () => <FeatureCard item={x} />,
  }));

  return (
    <div {...block()}>
      <div {...element('inner', {}, 'w-full')}>
        <div className="grid-layout mx-auto">
          <MediaQuery lessThan="lg">
            <BlockCaption color="purple1" bgcolor="purple3" center={false}>
              {t('caption', blockName)}
            </BlockCaption>
          </MediaQuery>
          <MediaQuery greaterThan="md">
            <BlockCaption color="purple1" bgcolor="purple3">
              {t('caption', blockName)}
            </BlockCaption>
          </MediaQuery>
          <Typography variant="header2" className="mt-4 sm:mt-3" center>
            {t('title', blockName)}
          </Typography>
          <MediaQuery lessThan="lg">
            <AccordionMenu items={accordionItems} className="py-7" />
          </MediaQuery>
          <MediaQuery greaterThan="md">
            <div className="mt-9 grid grid-cols-2 gap-4">
              {
                items.map((item, i) => (
                  <FeatureCard item={item} key={i} />
                ))
              }
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

ExtraFeatures.propTypes = {
  t: PropTypes.func.isRequired,
  BlockCaption: PropTypes.elementType.isRequired,
  items: PropTypes.array.isRequired,
};

export default ExtraFeatures;
