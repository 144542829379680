import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { ClientLogos } from '@widgets/client-logos';

const Feedback = () => {
  return (
    <section className="py-15 sm:py-7 md:py-8">
      <Typography
        variant="header2"
        center
        className=""
      >
        {t('main_page.feedback.title')}
      </Typography>
      <ClientLogos className="mt-9 sm:mt-7 md:mt-8" />
    </section>
  );
};

export default Feedback;
